import { IGif } from '@giphy/js-types'
import { SyntheticEvent, useContext, useState, ReactNode } from 'react'
import { useHistory } from 'react-router'
import { mobileFetch } from 'shared/api'
import GifContext from 'shared/contexts/gif'
import ScrollCarousel from 'shared/components/scroll-carousel'
import SharedSectionHeader, { SectionHeaderWithSeeAll } from 'shared/components/section-header'
import useAsyncEffect from 'shared/hooks/use-async-effect'
import { relativeUrl } from 'shared/util/url'
import styled from 'styled-components'
import HomepageHeader from 'shared/components/homepage-section-header'

const SectionHeader = styled(SharedSectionHeader)`
    margin-top: 20px;
`

type Props = {
    headerText?: string
    hideSeeAll?: boolean
    marginBottom?: number
    isInDropdown?: boolean
    limit?: number
    isStickers?: boolean
    className?: string
    icon?: ReactNode
    isHomepage?: boolean
    isMobileTrending?: boolean
    onGifSelected?: (e: SyntheticEvent<HTMLElement, Event>, gif: IGif) => void
}

const TrendingGifsCarousel = ({
    hideSeeAll,
    headerText,
    marginBottom = 30,
    onGifSelected,
    isInDropdown,
    isStickers,
    className,
    limit = 25,
    icon,
    isHomepage = false,
}: Props) => {
    const headerLabel = headerText || `Trending ${isStickers ? 'Stickers' : 'GIFs'}`
    const headerUrl = isStickers ? '/stickers' : '/trending-gifs'
    const { receivedGifs } = useContext(GifContext)
    const [gifs, setGifs] = useState<IGif[]>([])
    const history = useHistory()
    useAsyncEffect(async () => {
        const type = isStickers ? 'stickers' : 'gifs'
        const { data: gifs } = await mobileFetch.trending({ type, limit })
        // we don't always need to do this, but here we do for a spa to GifDetail which will pull from` redux
        receivedGifs({ gifs })
        setGifs(gifs)
    }, [])
    const onGifClick = (e: SyntheticEvent<HTMLElement, Event>, gif: IGif) => {
        onGifSelected?.(e, gif)
        history.push(relativeUrl(gif.url))
    }
    return (
        <>
            {isHomepage ? (
                <HomepageHeader
                    label={headerLabel}
                    seeAllLabel={`All The Gifs`}
                    icon={icon}
                    isMobileTrending
                    url={`/trending-gifs`}
                />
            ) : hideSeeAll ? (
                <SectionHeader label={headerLabel} url={headerUrl} isHistoryPush />
            ) : (
                <SectionHeaderWithSeeAll
                    label={headerLabel}
                    seeAllLabel={`All The Gifs`}
                    url={headerUrl}
                    isHistoryPush={!isStickers}
                />
            )}
            <ScrollCarousel
                height={120}
                showTags={false}
                gifs={gifs}
                onClick={onGifClick}
                marginBottom={marginBottom}
                className={className}
                isInDropdown={isInDropdown}
            />
        </>
    )
}

export default TrendingGifsCarousel
